<template>
  <div>
    <div class="breadcrumb pl-15">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/diagnosis' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>评价晒单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="contain">
      <div class="box">
        <div class="left">
          <div class="recomBlock flex jc-center">
            <div class="top">
              <div class="img mt-30 mb-20">
                <img src="./img/erweima.png" />
                <!-- <img :src="photoURL + InfOjb.head" alt="" /> -->
              </div>
              <!-- {{ InfOjb.name }}{{ InfOjb.city }} v-model="InfOjb.score"-->
              <div class="flex flex-col jc-center ai-center">
                <span class="Recomword1">ffff</span>
                <span class="Recomword2">诊断专家·dddd</span>
                <span class="pt-10 pb-30">¥<span>1200.00</span></span>
                <span class="pb-10">吐司机诊断服务</span>
                <span>9889人评价</span>
              </div>
            </div>
          </div>
          <el-divider></el-divider>
          <div class="oderBottom flex flex-col jc-center ai-center zhengwen2">
            <div class="my-25">订单编号:98898788777</div>
            <div>下单时间:98898788777</div>
          </div>
        </div>
        <div class="right">
          <!-- 表单 -->
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="服务态度">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="工作态度">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="完成质量">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="印象标签">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="填写评价">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="填写评价">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style lang='scss' scoped >
.breadcrumb {
  letter-spacing: 1px;
  width: 1440px;
  height: 40px;
  color: #8c8c8c;
  background-color: #efeff4;
  margin: 0 auto;
  .el-breadcrumb {
    display: flex;
    align-items: center;
    height: 40px;
  }
}
.contain {
  width: 1440px;
  background-color: #f5f5f5;
  height: 766px;
  margin: 0 auto;
  display: flex;
  .box {
    background-color: #f5f5f5;
    padding: 20px 15px 20px 15px;
    width: 100%;
    display: flex;
    .left {
      width: 373px;
      border-right: 1px solid #d1d1d6;
      background-color: white;
      .recomBlock {
        margin: 0 auto;
        width: 340px;
        text-align: center;
        .img {
          width: 300px;
          height: 300px;
          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
        }
        .Recomword1 {
          font-weight: 600;
          color: #262626;
          font-size: 20px;
          margin: 12px 0;
        }
        .Recomword2 {
          color: #888888;
          font-size: 16px;
          margin: 12px 0;
        }
        .rate {
          margin: 20px 0;
        }
      }
    }
    .right {
      padding-left: 20px;
      background-color: white;
      height: 100%;
      flex: 1;
    }
  }
}
.el-divider {
  margin: 30px auto 0;
  width: 90%;
}
</style>